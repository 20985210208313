import { createStore, applyMiddleware, combineReducers } from 'redux';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import thunkMiddleware from 'redux-thunk';
import user from './user';
import intake from './intake';
import buyerIntake from './buyerIntake';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import homeownerIntake from './homeownerIntake';
import agentIntake from './agentIntake';
const combinedReducer = combineReducers({
  user,
  intake,
  homeownerIntake,
  buyerIntake,
  agentIntake,
});

const bindMiddleware = (middleware: any) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

export const storyStore: any = createStore(bindMiddleware([thunkMiddleware]));

export const makeStore = ({ isServer }: any) => {
  if (isServer) {
    return createStore(combinedReducer, bindMiddleware([thunkMiddleware]));
  } else {
    const persistConfig = {
      key: 'nextjs',
      whitelist: [
        'user',
        'intake',
        'homeownerIntake',
        'buyerIntake',
        'agentIntake',
      ],
      storage,
    };

    const persistedReducer = persistReducer(persistConfig, combinedReducer);

    const store: any = createStore(
      persistedReducer,
      bindMiddleware([thunkMiddleware]),
    );

    store.__persistor = persistStore(store);

    return store;
  }
};

export const wrapper = createWrapper(makeStore);
