import React, { ReactNode } from 'react';

/**
 * Route
 */
export interface RouteProps {
  path: string;
  children: ReactNode;
}

const Route: React.FC<RouteProps> = () => {
  return null;
};

export default Route;
