import React, {
  InputHTMLAttributes,
  ReactNode,
  useState,
  ChangeEvent,
  FocusEvent,
  forwardRef,
} from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as Styled from './styles';
import { Margin } from '@app/utils/modifiers';

/**
 * Input
 */
export interface InputProps
  extends InputHTMLAttributes<HTMLInputElement>,
    Styled.StyledInputProps {
  id?: string;
  /**
   * show icon on the right side
   */
  name?: string;
  icon?: ReactNode;
  margin?: Margin;
  width?: string;
  required?: boolean;
  isPrice?: boolean;
  useValue?: boolean;
  removeBorder?: boolean;
  staticTitle?: boolean;
  clickIcon?: () => void;
  /**
   * show the clear button
   */
  showClearButton?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type = 'text',
      disabled = false,
      $valid = true,
      margin,
      icon,
      width,
      required = false,
      showClearButton = false,
      value = '',
      defaultValue = '',
      onChange,
      isPrice,
      onFocus,
      onBlur,
      name,
      mask,
      variant,
      placeholder = '',
      useValue,
      removeBorder = false,
      staticTitle = false,
      id,
      clickIcon,
      ...rest
    }: InputProps,
    ref,
  ) => {
    const [tempValue, setTempValue] = useState(value);
    const [isFocused, setIsFocused] = useState(false);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setTempValue(event.target.value);

      onChange && onChange(event);
    };

    const handleBlur = (event: FocusEvent<HTMLInputElement, Element>) => {
      setTempValue(event.target.value);
      setIsFocused(false);

      onBlur && onBlur(event);
    };

    const handleClearText = () => {
      setTempValue('');
    };

    const clickFloatLabel = () => {
      if (id) {
        const input = document.getElementById(id);
        input?.focus();
      }
    };

    const returnPlaceholder = () => {
      if (staticTitle) return placeholder;
      return '';
    };

    return (
      <Styled.TextFieldWrapper width={width}>
        {!staticTitle && (
          <Styled.FloatLabel
            up={Boolean((tempValue as string).length || isFocused)}
            onClick={clickFloatLabel}
            disabled={disabled}
            variant={variant}
          >
            {placeholder}
          </Styled.FloatLabel>
        )}
        <Styled.InputContainer
          margin={margin}
          disabled={disabled}
          variant={variant}
          $valid={$valid}
          removeBorder={removeBorder}
        >
          {isPrice && (
            <Styled.DolarSign disabled={disabled}>$</Styled.DolarSign>
          )}
          {mask ? (
            <Styled.MaskedInput
              id={id}
              name={name}
              mask={mask}
              disabled={disabled}
              value={useValue ? value : tempValue}
              onChange={handleChange}
              onFocus={() => setIsFocused(true)}
              onBlur={handleBlur}
              placeholder={returnPlaceholder()}
              // @ts-ignore: ref property throwing ts error in this library but works correctly
              ref={ref}
              {...rest}
            />
          ) : (
            <Styled.Input
              id={id}
              name={name}
              type={type}
              disabled={disabled}
              value={useValue ? value : tempValue}
              onChange={handleChange}
              onFocus={(e) => {
                setIsFocused(true);
                onFocus && onFocus(e);
              }}
              onBlur={handleBlur}
              placeholder={returnPlaceholder()}
              ref={ref}
              {...rest}
            />
          )}
          {showClearButton && tempValue.toString().length >= 1 && (
            <Styled.ClearButton
              data-testid="clear"
              onClick={handleClearText}
              disabled={disabled}
            >
              <FontAwesomeIcon icon={['far', 'times']} />
            </Styled.ClearButton>
          )}
          {icon && (
            <Styled.IconContainer
              data-testid="icon"
              disabled={disabled}
              onClick={clickIcon && clickIcon}
            >
              {icon}
            </Styled.IconContainer>
          )}
          {required && (
            <Styled.RequiredButton disabled={disabled}>
              <FontAwesomeIcon icon={['far', 'asterisk']} />
            </Styled.RequiredButton>
          )}
        </Styled.InputContainer>
      </Styled.TextFieldWrapper>
    );
  },
);

export default Input;
