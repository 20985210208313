export const GET_USER = `
  query {
    getUser {
      contactPreferences
      email
      firstName
      userId
      isBuyer
      isHomeowner
      lastName
      subscription {
        userId
        subscriptionRenewal
        subscriptionType
        subscriptionId
        stripeSubscriptionId
        stripeCustomerId
        stripeCustomerEmail
        status
        startedAt
        endedAt
      }
      pendingUserAndAgent {
        agentFirstName
        agentLastName
        bulkAgentId
        city
        email
        pendingUserAndAgentId
        state
        userId
        bulkAgent {
          city
          firstName
          lastName
        }
      }
      phone
      userType
      agent {
        agentImage {
          fkId
          imageId
          filename
        }
        agentShortId
        agentId
        userId
        preferredAgent
        state
        manualEmail
        licenseExpirationDate
        license
        lastName
        firstName
        description
        cities {
          stateName
          usCityId
          city
        }
        agentSlug
      }
      userAndAgent {
        agentId
        userAndAgentId
        userId
        agent {
          agentShortId
          agentId
          userId
          preferredAgent
          state
          manualEmail
          licenseExpirationDate
          license
          lastName
          firstName
          description
          city
        }
      }
    }
  }
`;
