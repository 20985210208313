import API from '@aws-amplify/api';
import { useAsync } from '.';

export enum GRAPHQL_AUTH_MODE {
  API_KEY = 'API_KEY',
  AWS_IAM = 'AWS_IAM',
  OPENID_CONNECT = 'OPENID_CONNECT',
  AMAZON_COGNITO_USER_POOLS = 'AMAZON_COGNITO_USER_POOLS',
  AWS_LAMBDA = 'AWS_LAMBDA',
}

interface QueryProps<T> {
  query: string;
  immediate?: boolean;
  variables?: object;
  onResult?: (value: T, params?: any, state?: any) => void;
  onFail?: (exception: Error) => void;
  useIsLoading?: boolean;
  authMode?: keyof typeof GRAPHQL_AUTH_MODE;
}

function useQuery<T>({
  query,
  immediate = false,
  variables,
  onResult,
  onFail,
  useIsLoading,
  authMode = 'AMAZON_COGNITO_USER_POOLS',
}: QueryProps<T>) {
  const { value, execute, refetch, error, loading } = useAsync<T>({
    asyncFunction: async function (newVariables?: object) {
      const result = await API.graphql({
        query,
        variables: newVariables || variables,
        authMode,
      });
      return (result as any)?.data as T;
    },
    immediate,
    onResult,
    onFail,
    useIsLoading,
  });

  return {
    response: (value as T) || ({} as T),
    execute,
    refetch,
    error,
    loading,
  };
}

export default useQuery;
