import { useRouter } from 'next/router';
import { Children, cloneElement, isValidElement, useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';

/**
 * Route
 */
export type RouteProps = {
  initialRoute?: any;
  onNavigate?: (newPath: string) => void;
};

const Routes: React.FC<
  RouteProps & { [key: Exclude<string, keyof RouteProps>]: any }
> = ({ children, initialRoute, onNavigate, ...props }) => {
  const [current, changeRoute] = useStateIfMounted(initialRoute || '');
  const router = useRouter();
  const navigateTo = (path: string) => {
    onNavigate?.(path);
    router?.push(path, undefined, {
      shallow: true,
    });
    changeRoute(path);
  };

  useEffect(() => {
    if (current !== initialRoute) {
      navigateTo(initialRoute);
    }
  }, [initialRoute]);

  const routes: React.ReactElement[] = [];

  Children.toArray(children).forEach((child, index) => {
    if (isValidElement(child) && typeof child !== 'string') {
      routes.push(
        cloneElement(Children.only(child.props.children), {
          key: child.props.path,
          navigateTo,
          path: child.props.path,
          goNext: () => {
            if (index + 1 in routes) {
              const path = routes[index + 1].props.path;
              navigateTo(path);
            }
          },
          goBack: () => {
            if (index - 1 in routes) {
              const path = routes[index - 1].props.path;
              navigateTo(path);
            }
          },
          ...props,
        }),
      );
    }
  });

  const currentRoute = routes.find(
    (route) => !current || route.props.path === current,
  );

  if (!currentRoute && currentRoute !== '')
    console.warn(`Current route "${currentRoute}" doesn't exist.`);
  return currentRoute || routes[0] || null;
};

export default Routes;
