import {
  Margin,
  marginModifier,
  Padding,
  paddingModifier,
} from '@app/utils/modifiers';
import styled, { css } from 'styled-components';

export type TextVariant =
  | 'base'
  | 'xs'
  | 'sm'
  | 'lg'
  | 'xl'
  | '1xl'
  | 'xxl'
  | 'xxxl';

export type StyledTextProps = {
  variant?: TextVariant;
  underline?: boolean;
  color?: string;
  textAlign?: 'left' | 'center' | 'right';
  uppercase?: boolean;
  bold?: boolean;
  lite?: boolean;
  required?: boolean;
  margin?: Margin | number;
  opacity?: number | false;
  padding?: Padding | number;
  breakLine?: boolean;
};

export type VariantModifiersProps = {
  bold?: boolean;
  lite?: boolean;
};

const variantModifiers = ({}: VariantModifiersProps) => ({
  xs: css`
    font-size: 10px;
    line-height: 12px;
  `,
  sm: css`
    font-size: 12px;
    line-height: 14px;
  `,
  base: css`
    font-size: 14px;
    line-height: 16px;
  `,
  lg: css`
    font-size: 18px;
    line-height: 20px;
  `,
  xl: css`
    font-size: 20px;
    line-height: 22px;
  `,
  '1xl': css`
    font-size: 22px;
    line-height: 24px;
  `,
  xxl: css`
    font-size: 25px;
    line-height: 27px;
  `,
  xxxl: css`
    font-size: 28px;
    line-height: 34px;
  `,
});

const requiredModifiers = {
  required: css`
    display: flex;
  `,
  notRequired: css``,
};

export const Text = styled.p<StyledTextProps>`
  color: ${({ color = '#4f4f4f' }) =>
    ({ lite }) =>
      lite ? '#767676' : color};
  font-weight: ${({ bold }) => (bold ? '600' : '400')};
  text-align: ${({ textAlign = 'left' }) => textAlign};
  font-family: 'Inter';
  font-style: normal;
  text-transform: ${({ uppercase = false }) => uppercase && 'uppercase'};
  ${({ variant = 'base', bold = false, lite = false }) =>
    variantModifiers({ bold, lite })[variant]};
  ${({ margin }) => marginModifier(margin)};
  ${({ padding }) => paddingModifier(padding)};
  opacity: ${({ opacity }) => opacity || 1};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
  white-space: ${({ breakLine }) => (breakLine ? 'pre-wrap' : 'normal')};
  ${({ required }) =>
    required ? requiredModifiers.required : requiredModifiers.notRequired};
`;

export const Required = styled.span`
  margin-left: 3px;
  font-weight: 900;
  font-size: 14px;
  line-height: 14px;
  color: ${(props) => props.theme.colors.secondary};
`;
