import React, { forwardRef, ButtonHTMLAttributes, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as Styled from './styles';
import Img from '../Img';

/**
 * Google Button
 */
export interface GoogleButtonProps
  extends Omit<Styled.StyledButtonProps, 'isMouseDown'>,
    ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
}

const GoogleButton = forwardRef<HTMLButtonElement, GoogleButtonProps>(
  ({ children, loading, ...rest }: GoogleButtonProps, ref) => {
    const [isMouseDown, setMouseDown] = useState(false);

    const handleMouseDown = () => {
      setMouseDown(true);
    };

    const handleMouseUp = () => {
      setMouseDown(false);
    };

    const handleGoogleLogin = () => {
      window.open('/oauth', 'Google', 'width=500,height=700');
    };

    return (
      <Styled.Button
        ref={ref}
        {...rest}
        isMouseDown={isMouseDown}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onClick={handleGoogleLogin}
      >
        <Styled.ImgContainer>
          <Img
            src="images/google.png"
            layout="fill"
            objectFit="contain"
            alt="Trusty - Home Matching Network"
          />
        </Styled.ImgContainer>
        Log in with Google
        {loading && (
          <FontAwesomeIcon
            icon={['fad', 'spinner-third']}
            size="1x"
            spin
            style={{ marginLeft: 10 }}
          />
        )}
      </Styled.Button>
    );
  },
);

export default GoogleButton;
