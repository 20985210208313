import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as Styled from './styles';
import { CurrencyInputProps } from 'react-currency-input-field';

/**
 * Currency Input
 */
export type CurrencyInputOnChangeValues = {
  float: number | null;
  formatted: string;
  value: string;
};

export interface Props extends CurrencyInputProps {
  onValueChange?: (
    value: string | undefined,
    name?: string,
    values?: CurrencyInputOnChangeValues,
  ) => void;
  width?: string;
  variant?: 'md' | 'lg';
  value: string | undefined;
  placeholder?: string;
  staticTitle?: boolean;
  min?: number;
  max?: number;
  disabled?: boolean;
  required?: boolean;
  small?: boolean;
  hasPrefix?: boolean;
  hideTitle?: boolean;
  inputWidth?: number;
  showFixedPrefix?: boolean;
  fullWidth?: boolean;
  disabledColor?: string;
}

const CurrencyInput = ({
  onValueChange,
  width,
  inputWidth,
  variant,
  value,
  placeholder,
  staticTitle = false,
  min,
  max,
  disabled,
  required,
  small,
  hasPrefix = true,
  hideTitle = false,
  showFixedPrefix = false,
  fullWidth,
  disabledColor,
  ...rest
}: Props) => {
  return (
    <Styled.TextFieldWrapper fullWidth={fullWidth}>
      {!hideTitle ? (
        <Styled.Label variant="sm" color="#888888">
          {placeholder}
        </Styled.Label>
      ) : null}
      <Styled.InputContainer
        variant={variant}
        disabled={disabled}
        small={small}
        inputWidth={inputWidth}
        fullWidth={fullWidth}
        disabledColor={disabledColor}
      >
        {showFixedPrefix ? (
          <Styled.FixedPrefixContainer itemsCenter justifyCenter>
            <Styled.FixedPrefix color="#4F4F4F">$</Styled.FixedPrefix>
          </Styled.FixedPrefixContainer>
        ) : null}
        <Styled.CInput
          id="currency-input"
          placeholder={staticTitle ? placeholder : ''}
          allowDecimals={false}
          value={value}
          onValueChange={onValueChange}
          prefix={hasPrefix ? '$' : undefined}
          step={10}
          min={min}
          max={max}
          disabled={disabled}
          groupSeparator=","
          decimalSeparator="."
          disabledColor={disabledColor}
          {...rest}
        />
        {required ? (
          <Styled.InputRequired>
            <FontAwesomeIcon
              icon={['far', 'asterisk']}
              size="sm"
              color="#d47e5b"
            />
          </Styled.InputRequired>
        ) : null}
      </Styled.InputContainer>
    </Styled.TextFieldWrapper>
  );
};

export default CurrencyInput;
