import React, { ReactNode } from 'react';
import NextLink from 'next/link';

type LinkTypes = {
  children: ReactNode;
  href: string;
};

const Link = ({ children, href }: LinkTypes) => {
  return (
    <NextLink href={href}>
      <a style={{ width: '100%', textDecoration: 'none' }}>{children}</a>
    </NextLink>
  );
};

export default Link;
