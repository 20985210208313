import { css } from 'styled-components';

export type Margin = {
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
};

export type Padding = {
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
};

export const marginModifier = (margin?: Margin | number) => {
  if (!margin) {
    return css`
      margin: 0;
    `;
  }

  if (Number.isInteger(margin)) {
    return css`
      margin: ${margin}px;
    `;
  }

  const { top = 0, left = 0, right = 0, bottom = 0 } = margin as Margin;

  return css`
    margin-top: ${top}px;
    margin-left: ${left}px;
    margin-right: ${right}px;
    margin-bottom: ${bottom}px;
  `;
};

export const paddingModifier = (padding?: Padding | number) => {
  if (!padding) {
    return css`
      padding: 0;
    `;
  }

  if (Number.isInteger(padding)) {
    return css`
      padding: ${padding}px;
    `;
  }

  const { top = 0, left = 0, right = 0, bottom = 0 } = padding as Padding;

  return css`
    padding-top: ${top}px;
    padding-left: ${left}px;
    padding-right: ${right}px;
    padding-bottom: ${bottom}px;
  `;
};
