import React, {
  TextareaHTMLAttributes,
  useState,
  ChangeEvent,
  FocusEvent,
  forwardRef,
} from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as Styled from './styles';
import { Margin } from '@app/utils/modifiers';

/**
 * TextArea
 */
export interface TextAreaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement>,
    Styled.StyledTextAreaProps {
  id?: string;
  /**
   * show icon on the right side
   */
  margin?: Margin;
  width?: string;
  required?: boolean;
  staticTitle?: boolean;
  useValue?: boolean;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      disabled = false,
      $valid = true,
      margin,
      width,
      required = false,
      value = '',
      defaultValue = '',
      onChange,
      onBlur,
      variant,
      placeholder = '',
      staticTitle = false,
      id,
      useValue,
      ...rest
    }: TextAreaProps,
    ref,
  ) => {
    const [tempValue, setTempValue] = useState(value);
    const [isFocused, setIsFocused] = useState(false);

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      setTempValue(event.target.value);

      onChange && onChange(event);
    };

    const handleBlur = (event: FocusEvent<HTMLTextAreaElement, Element>) => {
      setTempValue(event.target.value);
      setIsFocused(false);

      onBlur && onBlur(event);
    };

    const clickFloatLabel = () => {
      if (id) {
        const input = document.getElementById(id);
        input?.focus();
      }
    };

    const returnPlaceholder = () => {
      if (staticTitle) return placeholder;
      return '';
    };

    return (
      <Styled.TextFieldWrapper>
        {!staticTitle ? (
          <Styled.FloatLabel
            up={Boolean((tempValue as string).length || isFocused)}
            onClick={clickFloatLabel}
            disabled={disabled}
            variant={variant}
          >
            {placeholder}
          </Styled.FloatLabel>
        ) : null}

        <Styled.TextAreaContainer
          width={width}
          margin={margin}
          disabled={disabled}
          variant={variant}
          $valid={$valid}
        >
          <Styled.TextArea
            id={id}
            disabled={disabled}
            placeholder={returnPlaceholder()}
            value={useValue ? value : tempValue}
            onChange={handleChange}
            onFocus={() => setIsFocused(true)}
            onBlur={handleBlur}
            ref={ref}
            {...rest}
          />
          {required && (
            <Styled.RequiredButton disabled={disabled}>
              <FontAwesomeIcon icon={['far', 'asterisk']} />
            </Styled.RequiredButton>
          )}
        </Styled.TextAreaContainer>
      </Styled.TextFieldWrapper>
    );
  },
);

export default TextArea;
