import React, { forwardRef, HTMLAttributes } from 'react';

import * as Styled from './styles';

/**
 * Heading
 */
export interface HeadingProps
  extends Styled.StyledHeadingProps,
    HTMLAttributes<HTMLHeadingElement> {}

const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ children, ...rest }: HeadingProps, ref) => {
    return (
      <Styled.Heading ref={ref} {...rest}>
        {children}
      </Styled.Heading>
    );
  },
);

export default Heading;
