import { useCallback, useEffect } from 'react';

import { useStateIfMounted } from 'use-state-if-mounted';
import * as Sentry from '@sentry/nextjs';

interface AsyncProps<T> {
  asyncFunction: (params?: any) => Promise<T>;
  immediate?: boolean;
  onResult?: (value: T, params?: any, state?: any) => void;
  onFail?: (error: Error) => void;
  useIsLoading?: boolean;
}

function useAsync<T>({
  asyncFunction,
  immediate = true,
  onResult,
  onFail,
  useIsLoading = false,
}: AsyncProps<T>) {
  const [value, setValue] = useStateIfMounted<{
    error?: Error;
    loading: boolean;
    value: T | null;
  }>({
    error: undefined,
    loading: false,
    value: null,
  });

  const execute = useCallback(
    async (params?: any, state?: any) => {
      if (useIsLoading) {
        setValue({
          error: undefined,
          loading: true,
          value: null,
        });
      }

      try {
        const response = await asyncFunction(params);
        setValue({
          error: undefined,
          loading: false,
          value: response,
        });

        if (onResult) {
          onResult(response, params, state);
        }
        return response;
      } catch (tempError) {
        console.log(tempError, 'tempError');
        setValue({
          error: tempError as Error,
          loading: false,
          value: null,
        });
        Sentry.captureException(tempError);
        if (onFail) {
          onFail(tempError as Error);
        }
        return tempError;
      }
    },
    [asyncFunction],
  );

  const refetch = useCallback(
    async (params?: any) => {
      try {
        const response = await asyncFunction(params);
        setValue({
          ...value,
          value: response,
        });

        if (onResult) {
          onResult(response, params);
        }
        return response;
      } catch (tempError) {
        console.log(tempError, 'tempError');
        setValue({
          error: tempError as Error,
          loading: false,
          value: null,
        });
        Sentry.captureException(tempError);
        if (onFail) {
          onFail(tempError as Error);
        }
        return tempError;
      }
    },
    [asyncFunction],
  );

  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [immediate]);

  return { execute, refetch, ...value };
}

export default useAsync;
