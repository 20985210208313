import React from 'react';
import Select, { CSSObjectWithLabel, Props } from 'react-select';
import { useTheme } from 'styled-components';

export type DropdownProps = Props & {};

const Dropdown = ({
  styles: stylesProp,
  components: componentsProp,
  ...rest
}: DropdownProps) => {
  const theme = useTheme();

  const styles = {
    control: (provided: CSSObjectWithLabel) => ({
      ...provided,
      border: 'none',
      borderRadius: '25px',
    }),
    input: () => ({
      color: '#4f4f4f',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '16px',
    }),
    container: (provided: CSSObjectWithLabel) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
    }),
    valueContainer: (provided: CSSObjectWithLabel) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
    }),
    placeholder: (provided: CSSObjectWithLabel) => ({
      ...provided,
      color: '#4f4f4f',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '16px',
      paddingLeft: '10px',
    }),
    dropdownIndicator: (provided: CSSObjectWithLabel) => ({
      ...provided,
      color: '#D47E5B',
    }),
    menu: (provided: CSSObjectWithLabel) => ({
      ...provided,
    }),
    singleValue: (provided: CSSObjectWithLabel) => ({
      ...provided,
      color: '#4f4f4f',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '16px',
      paddingLeft: '10px',
    }),
    option: (provided: CSSObjectWithLabel, state: any) => ({
      ...provided,
      color:
        state.isSelected || state.isFocused ? theme.colors.white : '#4f4f4f',
      backgroundColor:
        state.isSelected || state.isFocused ? '#E0B09C' : 'inherit',
      '&:hover': {
        backgroundColor:
          state.isSelected || state.isFocused ? '#E0B09C' : theme.colors.white,
      },
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      paddingLeft: '10px',
    }),
    ...stylesProp,
  };

  return <Select styles={styles} {...rest} />;
};

export default Dropdown;
