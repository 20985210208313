import { HYDRATE } from 'next-redux-wrapper';

const INTAKE_UPDATE = 'INTAKE_UPDATE';
const INTAKE_RESET = 'INTAKE_RESET';

export const agentIntakeUpdate = (agent: any) => ({
  type: INTAKE_UPDATE,
  payload: agent,
});

export const agentIntakeReset = () => {
  return {
    type: INTAKE_RESET,
  };
};

const initialState = {
  agent: null,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.agentIntake };
    case INTAKE_UPDATE:
      return { ...state, ...action.payload };
    case INTAKE_RESET:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
