import React, { forwardRef, HTMLAttributes, useEffect, useState } from 'react';

import * as Styled from './styles';

/**
 * Radio Input
 */
export interface RadioProps
  extends Styled.StyledRadioProps,
    Omit<HTMLAttributes<HTMLInputElement>, 'onChange'> {
  onChange?: (value: boolean) => void;
}

const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ checked = false, onChange, hasDot, ...rest }: RadioProps, ref) => {
    const [value, setValue] = useState(checked);
    useEffect(() => {
      setValue(checked);
    }, [checked]);

    const handleChange = () => {
      onChange && onChange(!value);
    };

    return (
      <Styled.Radio
        itemsCenter
        justifyCenter
        ref={ref}
        checked={value}
        onClick={handleChange}
        hasDot={hasDot}
        {...rest}
      >
        {hasDot && checked && <Styled.Dot />}
      </Styled.Radio>
    );
  },
);

export default Radio;
