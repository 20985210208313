import React, { forwardRef, HTMLAttributes, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Text from '@app/components/atoms/Text';
import * as Styled from './styles';
import { Margin } from '@app/utils/modifiers';
import { TextVariant } from '../Text/styles';

/**
 * Checkbox
 */
export interface CheckboxProps
  extends Styled.StyledCheckboxProps,
    Omit<HTMLAttributes<HTMLInputElement>, 'onChange'> {
  onChange?: (value: boolean, e: any) => void;
  name?: string;
  uncheckedBorder?: string;
  checkedBorder?: string;
  uncheckedBg?: string;
  checkedBg?: string;
  disabled?: boolean;
  label?: string;
  margin?: Margin;
  width?: string;
  labelVariant?: TextVariant;
  noPadding?: boolean;
  justifyStart?: boolean;
  noBorder?: boolean;
  checkColor?: string;
  alignStart?: boolean;
  disabledBorderColor?: string;
  disabledOpacity?: boolean;
}

const Checkbox = forwardRef<HTMLDivElement, CheckboxProps>(
  (
    {
      checked = false,
      name,
      onChange,
      size,
      width = 'auto',
      uncheckedBorder,
      checkedBorder,
      label,
      justifyStart,
      labelVariant,
      uncheckedBg,
      checkedBg,
      disabled,
      margin,
      noPadding,
      noBorder,
      checkColor,
      alignStart,
      disabledBorderColor,
      disabledOpacity = true,
      ...rest
    }: CheckboxProps,
    ref,
  ) => {
    const theme = useTheme();
    const [value, setValue] = useState(checked);

    useEffect(() => {
      setValue(checked);
    }, [checked]);

    const handleChange = (e: any) => {
      onChange && onChange(!value, e);
    };

    return (
      <Styled.Container
        itemsCenter={!alignStart}
        width={width}
        justifyCenter={!justifyStart}
        margin={margin}
        padding={noPadding ? {} : { top: 15, bottom: 15, left: 25, right: 25 }}
        label={label}
        row={!!label}
        onClick={(e) => (!disabled ? handleChange(e) : e.stopPropagation())}
        noBorder={noBorder}
        disabled={disabled}
        disabledOpacity={disabledOpacity}
      >
        <Styled.CheckBoxDiv
          name={name}
          size={size}
          ref={ref}
          checked={value}
          uncheckedBorder={uncheckedBorder}
          checkedBorder={checkedBorder}
          uncheckedBg={uncheckedBg}
          checkedBg={checkedBg}
          disabled={disabled}
          disabledBorderColor={disabledBorderColor}
        >
          {value && (
            <FontAwesomeIcon
              icon={['far', 'check']}
              color={checkColor || theme.colors.white}
              size="xs"
            />
          )}
        </Styled.CheckBoxDiv>
        <Styled.Input
          type="radio"
          checked={value}
          onChange={() => (!disabled ? handleChange : undefined)}
          {...rest}
        />
        {label && (
          <Text variant={labelVariant ? labelVariant : 'base'}>{label}</Text>
        )}
      </Styled.Container>
    );
  },
);

export default Checkbox;
