import { HYDRATE } from 'next-redux-wrapper';

const INTAKE_UPDATE = 'INTAKE_UPDATE';
const INTAKE_RESET = 'INTAKE_RESET';

export const homeownerIntakeUpdate = (user: any) => ({
  type: INTAKE_UPDATE,
  payload: user,
});

export const homeownerIntakeReset = () => {
  return {
    type: INTAKE_RESET,
  };
};

const initialState = {
  homeowner: null,
  agent: null,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.homeownerIntake };
    case INTAKE_UPDATE:
      return { ...state, ...action.payload };
    case INTAKE_RESET:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
