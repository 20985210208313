import React, { forwardRef, HTMLAttributes } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as Styled from './styles';

/**
 * Text
 */
export interface TextProps
  extends Styled.StyledTextProps,
    HTMLAttributes<HTMLParagraphElement> {}

const Text = forwardRef<HTMLParagraphElement, TextProps>(
  ({ children, required = false, ...rest }: TextProps, ref) => {
    return (
      <Styled.Text ref={ref} required={required} {...rest}>
        {children}
        {required && (
          <Styled.Required>
            <FontAwesomeIcon icon={['far', 'asterisk']} size="sm" />
          </Styled.Required>
        )}
      </Styled.Text>
    );
  },
);

export default Text;
