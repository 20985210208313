import Image from 'next/image';
import * as Styled from './styles';
import NotFoundImage from 'public/images/notFound.svg';

const NotFound = () => {
  return (
    <Styled.Container>
      <Styled.MobileContainer itemsCenter>
        <Styled.MobileTitleWrapper>
          <Styled.Title color="#4F4F4F">Something went wrong</Styled.Title>
          <Styled.Divider />
        </Styled.MobileTitleWrapper>
      </Styled.MobileContainer>

      <Styled.WhiteContainer itemsCenter>
        <Image
          src={NotFoundImage}
          layout="fill"
          objectFit="cover"
          alt="Not Found"
        />
        \
        <Styled.TitleWrapper>
          <Styled.Title color="#4F4F4F">Something went wrong</Styled.Title>
          <Styled.Divider />
        </Styled.TitleWrapper>
      </Styled.WhiteContainer>
    </Styled.Container>
  );
};

export default NotFound;
