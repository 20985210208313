import styled from 'styled-components';
import FlexBox from '@app/components/atoms/FlexBox';
import { Text } from '@app/components/atoms';

export const Container = styled(FlexBox)`
  position: relative;
  width: 100%;
  max-width: 1550px;
  margin: 0 auto 50px;
  padding-bottom: 20px;
  padding-top: 10px;
  box-sizing: border-box;

  @media screen and (min-width: 1441px) {
    width: 100%;
  }

  @media screen and (max-width: 1590px) {
    padding: 10px 20px 50px;
  }

  @media screen and (max-width: 975px) {
    padding: 10px 20px 20px;
  }
`;

export const WhiteContainer = styled(FlexBox)`
  width: 800px;
  height: 459px;
  background: #ffffff;
  margin: 0 auto;
  position: relative;

  @media screen and (max-width: 975px) {
    width: 100%;
    height: unset;
    aspect-ratio: 16 / 9;
  }
`;

export const TitleWrapper = styled(FlexBox)`
  position: absolute;
  top: 42px;
  align-self: center;

  @media screen and (max-width: 975px) {
    display: none;
  }
`;

export const Title = styled(Text)`
  font-size: 25px;
  font-weight: 600;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #888;
  margin-top: 22px;
`;

export const MobileContainer = styled(FlexBox)`
  width: 100%;
  margin-bottom: 36px;

  @media screen and (min-width: 975px) {
    display: none;
  }
`;

export const MobileTitleWrapper = styled(FlexBox)`
  width: fit-content;
`;
