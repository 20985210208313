import { forwardRef, useRef } from 'react';
import Image, { ImageProps } from 'next/image';
import React from 'react';

/**
 * Img
 */
export interface ImgProps extends ImageProps {
  blur?: boolean;
}

const Img = forwardRef<HTMLImageElement, ImgProps>(
  ({ src, alt, blur = true, blurDataURL, ...rest }: ImgProps, ref) => {
    const { CDN_URL } = process.env;
    const imageRef = useRef(ref);

    let nextBlur: 'blur' | 'empty' | undefined = 'blur';
    if (!blur) {
      nextBlur = 'empty';
    }

    if (typeof src !== 'string') {
      return <Image src={src} alt={alt} placeholder={nextBlur} {...rest} />;
    }

    let tempSrc = `/${src.replace(/^\/|\/$/g, '')}`;
    if (CDN_URL) {
      tempSrc = `${CDN_URL.replace(/^\/|\/$/g, '')}${tempSrc}`;
    }

    return (
      <Image
        src={tempSrc}
        alt={alt}
        placeholder="blur"
        blurDataURL={
          blurDataURL ||
          'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
        }
        {...rest}
      />
    );
  },
);

export default Img;
