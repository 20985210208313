import React, { HTMLAttributes } from 'react';

import * as Styled from './styles';
import Link from 'next/link';
import { FlexBox } from '@app/components/atoms';
import Image from 'next/image';

/**
 * Header
 */
export interface SimpleHeaderProps extends HTMLAttributes<HTMLDivElement> {
  hasBgColor?: boolean;
}

const SimpleHeader = ({ hasBgColor, ...rest }: SimpleHeaderProps) => {
  return (
    <Styled.Container hasBgColor={hasBgColor}>
      <Styled.Content {...rest}>
        <FlexBox row itemsCenter>
          <Styled.ImageContainer>
            <Link href="/" passHref>
              <Image
                src="/images/logo3.svg"
                layout="fill"
                objectFit="contain"
                alt="Trusty - Home Matching Network"
              />
            </Link>
          </Styled.ImageContainer>

          <Styled.ImageContainerMobile>
            <Link href="/" passHref>
              <Image
                src="/images/trustyMobile.svg"
                layout="fill"
                objectFit="contain"
                alt="Trusty - Home Matching Network"
              />
            </Link>
          </Styled.ImageContainerMobile>
        </FlexBox>
      </Styled.Content>
    </Styled.Container>
  );
};

export default SimpleHeader;
