import React, { useRef } from 'react';
import { ColProps } from 'react-grid-system';

import * as Styled from './styles';

/**
 * Column
 */
export interface ColumnProps extends Styled.StyledColProps {}

const Column = (props: ColumnProps) => {
  // @ts-ignore
  return <Styled.Column {...props} />;
};

export default Column;
