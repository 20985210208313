import styled from 'styled-components';
import { Padding, paddingModifier } from '@app/utils/modifiers';
import { number } from 'yup';

export type StyledBoxProps = {
  width?: string;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  backgroundColor?: string;
  padding?: Padding | number;
};

export const Box = styled.div<StyledBoxProps>`
  width: ${({ width }) => (width ? `${width}` : 'auto')};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '0')};
  margin-right: ${({ marginRight }) =>
    marginRight ? `${marginRight}px` : '0'};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : '0'};
  margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : '0')};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? `${backgroundColor}` : 'inherit'};
  ${({ padding }) => paddingModifier(padding)};

  @media screen and (max-width: 975px) {
    width: auto;
  }
`;
