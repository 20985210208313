import styled, { css } from 'styled-components';
import { Col, ColProps, Align, Justify } from 'react-grid-system';
import { Margin, marginModifier } from '@app/utils/modifiers';

export interface StyledColProps extends ColProps {
  align?: Align;
  justify?: Justify;
  margin?: Margin | number;
}

const alignModifiers = {
  normal: css`
    display: flex;
    align-items: baseline;
  `,
  start: css`
    display: flex;
    align-items: flex-start;
  `,
  center: css`
    display: flex;
    align-items: center;
  `,
  end: css`
    display: flex;
    align-items: flex-end;
  `,
  stretch: css`
    display: flex;
    align-items: stretch;
  `,
};

const justifyModifiers = {
  start: css`
    display: flex;
    justify-content: flex-start;
  `,
  center: css`
    display: flex;
    justify-content: center;
  `,
  end: css`
    display: flex;
    justify-content: flex-end;
  `,
  between: css`
    display: flex;
    justify-content: space-between;
  `,
  around: css`
    display: flex;
    justify-content: space-around;
  `,
  initial: css`
    display: flex;
    justify-content: initial;
  `,
  inherit: css`
    display: flex;
    justify-content: inherit;
  `,
};

export const Column = styled(Col)<StyledColProps>`
  ${({ align }) => align && alignModifiers[align]};
  ${({ justify }) => justify && justifyModifiers[justify]};
  ${({ margin }) => marginModifier(margin)};
`;
