import styled from 'styled-components';

export const Container = styled.div<{ hasBgColor?: boolean }>`
  position: relative;
  background-color: ${({ hasBgColor }) => (hasBgColor ? '#fff' : 'unset')};
  z-index: 2;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  margin: 0px auto 50px;
  max-width: 1550px;
  padding-bottom: 20px;
  padding-top: 10px;

  @media screen and (max-width: 1590px) {
    margin: 0px 20px 50px;
  }

  @media screen and (max-width: 975px) {
    margin: 0px 20px 20px;
  }
`;

export const ImageContainer = styled.div`
  height: 39px;
  width: 132px;
  position: relative;
  cursor: pointer;

  @media screen and (max-width: 975px) {
    display: none;
  }
`;

export const ImageContainerMobile = styled.div`
  height: 39px;
  width: 36px;
  position: relative;
  cursor: pointer;

  @media screen and (min-width: 975px) {
    display: none;
  }
`;
