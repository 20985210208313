import styled, { css } from 'styled-components';
import FlexBox from '../FlexBox';

const variantModifiers = (bold: boolean) => ({
  primary: css`
    font-weight: ${bold ? '800' : '500'};
    font-size: 16px;
  `,
  secondary: css`
    font-weight: ${bold ? '800' : '500'};
    font-size: 16px;
  `,
});

const colorModifiers = (color: string) => ({
  isActive: css`
    color: ${(props) => '#4f4f4f'};
  `,
  notActive: css`
    color: ${color};
  `,
});

export type StyledAnchorProps = {
  variant?: 'primary' | 'secondary';
  color?: string;
  isActive?: boolean;
  bold?: boolean;
};

export const Wrapper = styled(FlexBox)``;

export const Anchor = styled.a<StyledAnchorProps>`
  cursor: pointer;
  text-decoration: none;

  ${({ variant = 'primary', bold = false }) => variantModifiers(bold)[variant]};
  ${({ isActive = false, color = '#4f4f4f' }) =>
    isActive ? colorModifiers(color).isActive : colorModifiers(color).notActive}

  &:hover {
    color: #30ead7;
  }
`;

export const Underline = styled.div<{ isActive?: boolean }>`
  width: 100%;
  height: 3px;
  margin-top: 12px;
  background: ${({ isActive, theme }) =>
    isActive ? '#2CD1C0' : 'transparent'};
`;
