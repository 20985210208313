import React, { forwardRef, ButtonHTMLAttributes, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as Styled from './styles';

/**
 * Button
 */
export interface ButtonProps
  extends Omit<Styled.StyledButtonProps, 'isMouseDown'>,
    ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  padding?: string;
  fontSize?: number;
  width?: string;
  disabledOpacity?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      width,
      loading,
      padding,
      fontSize,
      disabledOpacity,
      ...rest
    }: ButtonProps,
    ref,
  ) => {
    const [isMouseDown, setMouseDown] = useState(false);

    const handleMouseDown = () => {
      setMouseDown(true);
    };

    const handleMouseUp = () => {
      setMouseDown(false);
    };

    return (
      <Styled.Button
        ref={ref}
        {...rest}
        width={width}
        disabled={rest.disabled || loading}
        isMouseDown={isMouseDown}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        padding={padding}
        fontSize={fontSize}
        disabledOpacity={disabledOpacity}
      >
        {children}
        {loading && (
          <FontAwesomeIcon
            icon={['fad', 'spinner-third']}
            size="1x"
            spin
            style={{ marginLeft: 10 }}
          />
        )}
      </Styled.Button>
    );
  },
);

export default Button;
