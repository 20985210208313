import styled, { css } from 'styled-components';
import InputMask from 'react-input-mask';
import { Margin, marginModifier } from '@app/utils/modifiers';

export interface StyledInputContainerProps {
  disabled?: boolean;
  variant?: 'md' | 'lg';
  /**
   * Is the input valid?
   */
  $valid?: boolean;
  margin?: Margin;
  removeBorder?: boolean;
}

export interface StyledInputProps {
  /**
   * Is the input valid?
   */
  variant?: 'md' | 'lg';
  $valid?: boolean;

  /**
   * Mask string. Format characters are:
   * * `9`: `0-9`
   * * `a`: `A-Z, a-z`
   * * `\*`: `A-Z, a-z, 0-9`
   *
   * Any character can be escaped with backslash, which usually will appear as double backslash in JS strings.
   * For example, German phone mask with unremoveable prefix +49 will look like `mask="+4\\9 99 999 99"` or `mask={"+4\\\\9 99 999 99"}`
   */
  mask?: string | Array<string | RegExp>;
}

const validityModifier = {
  valid: () => css`
    &:valid {
      color: #4f4f4f;
    }
  `,
  invalid: () => css`
    border-color: #c10c12;
  `,
};

const variantModifiers = () => ({
  md: css`
    font-size: 16px;
    height: 50px;
  `,
  lg: css`
    font-size: 20px;
    height: 60px;
  `,
});

export const InputContainer = styled.div<StyledInputContainerProps>`
  width: 100%;
  background: ${({ disabled }) => (disabled ? '#F5F5F5' : '#ffffff')};
  border-radius: 5px;
  display: flex;
  align-items: flex-start;
  border: 1px solid
    ${({ disabled, removeBorder }) =>
      removeBorder ? 'transparent' : disabled ? '#DADADA' : '#c6c6c6'};
  padding: 0 15px;
  box-sizing: border-box;

  ${({ margin }) => marginModifier(margin)};
  ${({ variant = 'md' }) => variantModifiers()[variant]};
  ${({ $valid }) =>
    $valid ? validityModifier.valid : validityModifier.invalid};
`;

const StyledInput = css<{ disabled?: boolean }>`
  font-family: 'Inter';
  font-size: inherit;
  font-style: normal;
  font-weight: 500;

  background: transparent;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  color: #4f4f4f;

  ::placeholder {
    color: #888888;
    font-weight: 400;
    font-size: inherit;
  }

  input[type='date']:before {
    content: attr(placeholder) !important;
    color: #aaa;
    margin-right: 0.5em;
  }
`;

export const MaskedInput = styled(InputMask)<StyledInputProps>`
  ${StyledInput}
`;

export const Input = styled.input<StyledInputProps>`
  ${StyledInput}
`;

export const ClearButton = styled.div<{ disabled?: boolean }>`
  height: 100%;
  font-size: 18px;
  color: ${({ disabled }) => (disabled ? '#DADADA' : '#D47E5B')};
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  box-sizing: border-box;
`;

export const IconContainer = styled.div<{ disabled?: boolean }>`
  height: 100%;
  font-size: 18px;
  color: ${({ disabled }) => (disabled ? '#DADADA' : '#4F4F4F')};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  box-sizing: border-box;
`;

export const RequiredButton = styled.div<{ disabled?: boolean }>`
  height: 100%;
  font-size: 12px;
  color: #d47e5b;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 7px 0 7px 5px;
  box-sizing: border-box;
`;

export const DolarSign = styled.div<{ disabled?: boolean }>`
  height: 100%;
  font-size: 20px;
  font-weight: 800;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 16px 7px 5px;
  box-sizing: border-box;
`;

// Float Label
export const TextFieldWrapper = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: black;
  width: ${({ width }) => width || '100%'};
`;

const labelVariantModifiers = () => ({
  md: css`
    font-size: 16px;
  `,
  lg: css`
    font-size: 20px;
  `,
});

export const FloatLabel = styled.label<{
  up?: boolean;
  disabled?: boolean;
  variant?: 'md' | 'lg';
}>`
  will-change: transform;
  transition: transform 0.3s cubic-bezier(0.06, 0.67, 0.32, 0.82);
  transform: ${({ variant = 'md' }) =>
    variant === 'md' ? 'translate(18px, 35px)' : 'translate(18px, 42px)'};
  ${({ up }) =>
    up &&
    css`
      transform: scale(1) translate(0, -2px);
      transform-origin: left bottom;
    `}

  -webkit-font-smoothing: subpixel-antialiased;
  font-style: normal;
  color: ${({ disabled }) => (disabled ? '#DADADA' : '#888888')};
  cursor: text;
  z-index: 2;

  ${({ variant = 'md' }) => labelVariantModifiers()[variant]};
`;
