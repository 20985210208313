import React, { forwardRef, HTMLAttributes } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import * as Styled from './styles';

/**
 * Link Button
 */
export interface LinkButtonProps
  extends Styled.StyledAnchorProps,
    HTMLAttributes<HTMLAnchorElement> {
  href: string;
  active?: boolean;
  underline?: boolean;
}

const LinkButton = forwardRef<HTMLAnchorElement, LinkButtonProps>(
  (
    { children, color, href, active, underline, ...rest }: LinkButtonProps,
    ref,
  ) => {
    const router = useRouter();

    return (
      <Styled.Wrapper>
        <Link href={href} passHref>
          <Styled.Anchor
            ref={ref}
            isActive={active || router?.pathname === href}
            color={color}
            {...rest}
          >
            {children}
          </Styled.Anchor>
        </Link>
        {underline && (
          <Styled.Underline isActive={active || router?.pathname === href} />
        )}
      </Styled.Wrapper>
    );
  },
);

export default LinkButton;
