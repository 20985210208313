import { useEffect } from 'react';
import type { AppProps } from 'next/app';
import Router, { useRouter } from 'next/router';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import Script from 'next/script';
import * as snippet from '@segment/snippet';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import crypto from 'crypto';
import { loadIntercom, initIntercomWindow } from 'next-intercom';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../src/styles/rheostat.css';
import '../src/styles/toggle.css';
import '../src/styles/gac.css';
import 'react-datepicker/dist/react-datepicker.css';
import * as Sentry from '@sentry/nextjs';

import { importFontAwesomeIcons } from '@app/utils/fontAwesomeIcons';

import { progress } from '@app/utils/progressbar';
import Amplify from 'aws-amplify';
import defaultTheme from '@app/styles/themes/default';
import { wrapper } from '@app/store';
import { PersistGate } from 'redux-persist/integration/react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { parseCookies } from 'nookies';
import ErrorBoundary from '@app/components/atoms/ErrorBoundary';

import { useQuery } from '@app/hooks';
import { GET_USER } from '@app/queries/getUser';
import { userUpdate } from '@app/store/user';
import { setCookie } from '@app/utils/cookies';

Router.events.on('routeChangeStart', progress.start);
Router.events.on('routeChangeComplete', progress.finish);
Router.events.on('routeChangeError', progress.finish);

declare global {
  interface Window {
    analytics: any;
    google: any;
  }
}

Amplify.configure({
  aws_cognito_region: 'us-west-2',
  aws_user_pools_id: process.env.COGNITO_POOL_ID,
  aws_user_pools_web_client_id: process.env.COGNITO_WEB_CLIENT_ID,
  aws_appsync_graphqlEndpoint: process.env.ENDPOINT,
  aws_appsync_region: 'us-west-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: process.env.API_KEY,
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  oauth: {
    domain: process.env.COGNITO_URL,
    scope: ['email', 'profile', 'openid'],
    redirectSignIn: `${process.env.APP_URL}/oauth/success`,
    redirectSignOut: `${process.env.APP_URL}/logout`,
    responseType: 'code',
  },
});

const GlobalStyle = createGlobalStyle`
body {
  background: ${(props) => props.theme.colors.background};
  margin: 0px;
  padding: 0px;
  font-family: 'Inter', Arial;
  width: 100%;
}
.bar-of-progress {
  z-index: 50;
}
a {
  text-decoration: none;
}
`;

importFontAwesomeIcons();

function renderSnippet() {
  const opts = {
    apiKey: process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY,
    // note: the page option only covers SSR tracking.
    // Page.js is used to track other events using `window.analytics.page()`
    page: true,
  };

  if (process.env.NODE_ENV === 'development') {
    return snippet.max(opts);
  }

  return snippet.min(opts);
}

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const store: any = useStore();
  const dispatch = useDispatch();
  const userData: any = useSelector<any>((state) => state.user);
  const { trustyRole } = parseCookies();
  const cookies = parseCookies();
  const intercomCookiesArray = Object.keys(cookies).filter((cookie) =>
    cookie.includes('intercom'),
  );

  useEffect(() => {
    if (
      Object.keys(userData) &&
      Object.keys(userData)?.length > 0 &&
      !trustyRole
    ) {
      setCookie('trustyRole', userData?.userType);
    }
  }, [userData, trustyRole]);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
      LogRocket.init('trusty/trustyw22', {
        console: {
          shouldAggregateConsoleErrors: true,
          isEnabled: {
            log: false,
            debug: false,
          },
        },
      });
      setupLogRocketReact(LogRocket);
    }
  }, []);

  useEffect(() => {
    async function callIntercom(user: any) {
      const secretKey = process.env.NEXT_PUBLIC_INTERCOM_SECRET_KEY || '';
      const userIdentifier = user?.email;
      const hash = crypto
        .createHmac('sha256', secretKey)
        .update(userIdentifier || '')
        .digest('hex');
      const intercomBody = {
        appId: process.env.NEXT_PUBLIC_INTERCOM_ID || '',
        ...(userIdentifier && {
          userId: user?.userId,
          name: `${user?.firstName} ${user?.lastName}`,
          email: userIdentifier,
          userHash: hash,
        }),
        ssr: false,
        initWindow: false,
        delay: 500,
      };
      loadIntercom(intercomBody);
      initIntercomWindow({
        appId: process.env.NEXT_PUBLIC_INTERCOM_ID || '',
        email: userIdentifier,
      });
    }

    callIntercom(userData)
      .then(() => {
        setTimeout(() => {
          if (router.pathname === '/' && intercomCookiesArray.length < 1) {
            router.reload();
          }
        }, 1000);
      })
      .catch((err) => Sentry.captureException(err));
  }, [userData, intercomCookiesArray]);

  const handleGetUserPageLoad = (data: any) => {
    dispatch(userUpdate(data.getUser));
  };

  const getUserQuery = useQuery({
    query: GET_USER,
    onResult: handleGetUserPageLoad,
    onFail: (err) => Sentry.captureException(err),
  });

  useEffect(() => {
    const handleRouteChange = () => {
      if (
        Object.keys(userData) &&
        Object.keys(userData)?.length > 0 &&
        userData?.userType === 'agent'
      ) {
        getUserQuery.execute();
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);

  return (
    <>
      {/* Inject the Segment snippet into the <head> of the document  */}
      <ErrorBoundary key={router.pathname}>
        <ThemeProvider theme={defaultTheme}>
          <Script
            id="segment-script"
            dangerouslySetInnerHTML={{ __html: renderSnippet() }}
          />

          <GlobalStyle />
          <PersistGate loading={null} persistor={store.__persistor}>
            <Component {...pageProps} />
          </PersistGate>
        </ThemeProvider>
      </ErrorBoundary>
    </>
  );
}

export default wrapper.withRedux(MyApp);
