import styled, { css } from 'styled-components';
import CurrencyInput from 'react-currency-input-field';
import Text from '../Text';
import FlexBox from '../FlexBox';

export const TextFieldWrapper = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
`;

export const InputContainer = styled.div<{
  variant?: 'md' | 'lg';
  disabled?: boolean;
  small?: boolean;
  inputWidth?: number;
  fullWidth?: boolean;
  disabledColor?: string;
}>`
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  align-items: flex-start;
  border: 1px solid #c6c6c6;
  padding: 0 15px;
  box-sizing: border-box;
  position: relative;

  ${({ small, inputWidth, fullWidth }) =>
    small
      ? css`
          width: ${fullWidth
            ? '100%'
            : !fullWidth && inputWidth
            ? `${inputWidth}px`
            : '125px'};
          height: 45px;
          font-size: 14px;
        `
      : css`
          width: ${fullWidth
            ? '100%'
            : !fullWidth && inputWidth
            ? `${inputWidth}px`
            : '152px'};
          height: 50px;
          font-size: 16px;
        `}

  ${({ disabled, disabledColor }) =>
    disabled &&
    css`
      background: ${disabledColor || '#f2f2f2'};
    `}

  @media screen and (max-width: 500px) {
    width: ${({ fullWidth, inputWidth }) =>
      fullWidth
        ? '100%'
        : !fullWidth && inputWidth
        ? `${inputWidth}px`
        : '125px'};
    height: 45px;
    font-size: 14px;
  }
`;

export const CInput = styled(CurrencyInput)<{
  disabledColor?: string;
}>`
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  box-sizing: border-box;
  font-size: inherit;
  font-family: 'Inter';
  font-size: inherit;
  font-style: normal;
  font-weight: 500;
  color: #4f4f4f;
  &::placeholder {
    color: #888888;
  }

  ${({ disabled, disabledColor }) =>
    disabled &&
    css`
      background: ${disabledColor || '#f2f2f2'};
    `}
`;

export const Label = styled(Text)`
  font-weight: 400;
  margin-bottom: 9px;
`;

export const InputRequired = styled.div`
  position: absolute;
  top: 6px;
  right: 8px;
`;

export const FixedPrefixContainer = styled(FlexBox)`
  height: 100%;
`;

export const FixedPrefix = styled(Text)`
  font-weight: 400;
`;
