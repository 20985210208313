import { HYDRATE } from 'next-redux-wrapper';

const USER_UPDATE = 'USER_UPDATE';
const USER_RESET = 'USER_RESET';

export const userUpdate = (user: any) => ({
  type: USER_UPDATE,
  payload: user,
});

export const userReset = () => {
  return {
    type: USER_RESET,
  };
};

const initialState = {};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.user };
    case USER_UPDATE:
      return { ...state, ...action.payload };
    case USER_RESET:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
