import styled from 'styled-components';
import FlexBox from '../FlexBox';

export type StyledRadioProps = {
  checked?: boolean;
  hasDot?: boolean;
};

export const Radio = styled(FlexBox)<StyledRadioProps>`
  appearance: none;
  background: ${({ checked = false, hasDot }) =>
    !hasDot && checked ? '#E0B09C' : 'transparent'};
  border-radius: 22px;
  border: 3px solid #d47e5b;
  box-sizing: border-box;
  cursor: pointer;
  min-height: 22px;
  height: 22px;
  min-width: 22px;
  width: 22px;
`;

export const Dot = styled.div`
  min-height: 12px;
  height: 12px;
  min-width: 12px;
  width: 12px;
  background: #d47e5b;
  border-radius: 12px;
`;
