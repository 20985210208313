import React, { forwardRef, HTMLAttributes } from 'react';

import * as Styled from './styles';

/**
 * Box
 */
export interface FlexBoxProps
  extends Styled.StyledFlexBoxProps,
    HTMLAttributes<HTMLDivElement> {}

const FlexBox = forwardRef<HTMLDivElement, FlexBoxProps>(
  ({ children, ...rest }: FlexBoxProps, ref) => {
    return (
      <Styled.FlexBox ref={ref} {...rest}>
        {children}
      </Styled.FlexBox>
    );
  },
);

export default FlexBox;
