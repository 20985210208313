import styled, { css } from 'styled-components';

export type StyledButtonProps = {
  variant?: 'primary';
  isMouseDown: boolean;
};

const variantModifiers = {
  primary: css`
    background: #ffffff;
    box-shadow: 0px 4px 0px #c1c1c1;
    color: #4f4f4f;

    &:hover {
      background: #fefcf3;
    }
  `,
};

const mouseDownModifiers = {
  primary: css`
    box-shadow: none;
    transform: translateY(4px);
    transition: 100ms;
  `,
};

export const Button = styled.button<StyledButtonProps>`
  border: none;
  cursor: pointer;
  padding: 15px 30px 12px;
  border-radius: 30px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  text-align: center;
  display: flex;
  align-items: center;

  ${({ variant = 'primary' }) => variantModifiers[variant]};
  ${({ isMouseDown = false, variant = 'primary' }) =>
    isMouseDown && mouseDownModifiers[variant]};

  ${({ disabled }) =>
    disabled &&
    css`
      background: #b3b3b3;
      box-shadow: 0px 4px 0px #999999;

      &:hover {
        background: #b3b3b3;
      }
    `}
`;

export const ImgContainer = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  margin-right: 30px;
`;
