import styled, { css } from 'styled-components';
import { Margin, marginModifier } from '@app/utils/modifiers';

export interface StyledTextAreaContainerProps {
  disabled?: boolean;
  variant?: 'md' | 'lg';
  /**
   * Is the textarea valid?
   */
  $valid?: boolean;
  margin?: Margin;
  width?: string;
}

export interface StyledTextAreaProps {
  /**
   * Is the textarea valid?
   */
  variant?: 'md' | 'lg';
  $valid?: boolean;
}

const validityModifier = {
  valid: () => css`
    &:valid {
      color: #4f4f4f;
    }
  `,
  invalid: () => css`
    border-color: #c10c12;
  `,
};

const variantModifiers = () => ({
  md: css`
    font-size: 16px;
  `,
  lg: css`
    font-size: 20px;
  `,
});

export const TextAreaContainer = styled.div<StyledTextAreaContainerProps>`
  width: ${({ width }) => width || '100%'};
  background: ${({ disabled }) => (disabled ? '#F5F5F5' : '#ffffff')};
  border-radius: 5px;
  display: flex;
  align-items: flex-start;
  border: 1px solid ${({ disabled }) => (disabled ? '#DADADA' : '#c6c6c6')};
  padding: 0 15px;
  box-sizing: border-box;

  ${({ margin }) => marginModifier(margin)};
  ${({ variant = 'md' }) => variantModifiers()[variant]};
  ${({ $valid }) =>
    $valid ? validityModifier.valid : validityModifier.invalid};
`;

const StyledTextarea = css<{ disabled?: boolean }>`
  font-family: 'Inter';
  font-size: inherit;
  font-style: normal;
  font-weight: 500;

  background: transparent;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0;

  ::placeholder {
    color: ${({ disabled }) => (disabled ? '#DADADA' : '#969aa5')};
    font-weight: 400;
    font-size: inherit;
  }
`;

export const TextArea = styled.textarea<StyledTextAreaProps>`
  ${StyledTextarea}
  padding: 15px 0;
  resize: none;
`;

export const RequiredButton = styled.div<{ disabled?: boolean }>`
  height: 100%;
  font-size: 12px;
  color: ${({ disabled }) => (disabled ? '#DADADA' : '#D47E5B')};
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 7px 0 7px 5px;
  box-sizing: border-box;
`;

// Float Label
export const TextFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: black;
  width: 100%;
`;

const labelVariantModifiers = () => ({
  md: css`
    font-size: 16px;
  `,
  lg: css`
    font-size: 20px;
  `,
});

export const FloatLabel = styled.label<{
  up?: boolean;
  disabled?: boolean;
  variant?: 'md' | 'lg';
}>`
  will-change: transform;
  transition: transform 0.3s cubic-bezier(0.06, 0.67, 0.32, 0.82);
  transform: ${({ variant = 'md' }) =>
    variant === 'md' ? 'translate(18px, 35px)' : 'translate(18px, 42px)'};
  ${({ up }) =>
    up &&
    css`
      transform: scale(1) translate(0, -2px);
      transform-origin: left bottom;
    `}

  -webkit-font-smoothing: subpixel-antialiased;
  font-style: normal;
  color: ${({ disabled }) => (disabled ? '#DADADA' : '#888888')};
  cursor: text;
  z-index: 2;

  ${({ variant = 'md' }) => labelVariantModifiers()[variant]};
`;
