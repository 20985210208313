import styled from 'styled-components';
import { Padding, paddingModifier } from '@app/utils/modifiers';

export type StyledFlexBoxProps = {
  bg?: string;
  width?: string;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  justifyCenter?: boolean;
  justifyEnd?: boolean;
  justifyBetween?: boolean;
  justifyAround?: boolean;
  justifyEvenly?: boolean;
  itemsCenter?: boolean;
  itemsEnd?: boolean;
  selfEnd?: boolean;
  selfCenter?: boolean;
  row?: boolean;
  gap?: number;
  keepStyleOnMobile?: boolean;
  wrap?: string;
  padding?: Padding | number;
};

export const FlexBox = styled.div<StyledFlexBoxProps>`
  display: flex;
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};
  justify-content: ${({
    justifyCenter,
    justifyEnd,
    justifyBetween,
    justifyAround,
    justifyEvenly,
  }) =>
    justifyCenter
      ? 'center'
      : justifyEnd
      ? 'flex-end'
      : justifyBetween
      ? 'space-between'
      : justifyAround
      ? 'space-around'
      : justifyEvenly
      ? 'justify-evenly'
      : 'flex-start'};
  align-items: ${({ itemsCenter, itemsEnd }) =>
    itemsCenter ? 'center' : itemsEnd ? 'flex-end' : 'flex-start'};
  width: ${({ width }) => (width ? `${width}` : 'auto')};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '0')};
  background-color: ${({ bg }) => (bg ? bg : 'transparent')};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : '0'};
  margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : '0')};
  margin-right: ${({ marginRight }) =>
    marginRight ? `${marginRight}px` : '0'};
  gap: ${({ gap }) => (gap ? `${gap}px` : '0')};
  align-self: ${({ selfCenter, selfEnd }) =>
    selfCenter ? 'center' : selfEnd ? 'flex-end' : 'auto'};
  flex-wrap: ${({ wrap }) => wrap || 'no-wrap'};
  ${({ padding }) => paddingModifier(padding)};

  @media screen and (max-width: 975px) {
    width: ${({ width, keepStyleOnMobile }) =>
      keepStyleOnMobile ? `${width}` : 'auto'};
    margin-top: ${({ marginTop, keepStyleOnMobile }) =>
      keepStyleOnMobile ? `${marginTop}px` : '0'};
    margin-bottom: ${({ marginBottom, keepStyleOnMobile }) =>
      keepStyleOnMobile ? `${marginBottom}px` : '0'};
    margin-left: ${({ marginLeft, keepStyleOnMobile }) =>
      keepStyleOnMobile ? `${marginLeft}px` : '0'};
    margin-right: ${({ marginRight, keepStyleOnMobile }) =>
      keepStyleOnMobile ? `${marginRight}px` : '0'};
  }
`;
