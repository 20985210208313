import React, { forwardRef, HTMLAttributes } from 'react';

import * as Styled from './styles';
import SimpleHeader from '@app/components/molecules/SimpleHeader';

/**
 * Template Container
 */
export interface SimpleContainerProps extends HTMLAttributes<HTMLDivElement> {
  clickLogo?: () => void;
}

const SimpleContainer = forwardRef<any, SimpleContainerProps>(
  ({ children, ...rest }: SimpleContainerProps, ref) => {
    return (
      <>
        <Styled.Container ref={ref}>
          <Styled.BigContainer>
            <SimpleHeader />
          </Styled.BigContainer>

          <Styled.ChildrenContainer>{children}</Styled.ChildrenContainer>
        </Styled.Container>
      </>
    );
  },
);

export default SimpleContainer;
