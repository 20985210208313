import { Margin, marginModifier } from '@app/utils/modifiers';
import styled, { css } from 'styled-components';

export type StyledHeadingProps = {
  variant?: 'title' | 'h1' | 'h2' | 'h3' | 'h4';
  color?: string;
  textAlign?: 'left' | 'center' | 'right';
  bold?: boolean;
  margin?: Margin;
};

const variantModifiers = (bold: boolean) => ({
  title: css`
    font-weight: 600;
    font-size: 60px;
    line-height: 70px;

    @media screen and (max-width: 975px) {
      font-size: 35px;
      line-height: 45px;
    }
  `,
  h1: css`
    font-weight: ${bold ? '700' : '500'};
    font-size: 60px;
    line-height: 62px;

    @media screen and (max-width: 975px) {
      font-size: 45px;
      line-height: 47px;
    }
  `,
  h2: css`
    font-weight: ${bold ? '700' : '500'};
    font-size: 30px;
    line-height: 35px;

    @media screen and (max-width: 975px) {
      font-size: 25px;
      line-height: 30px;
    }

    @media screen and (max-width: 768px) {
      font-size: 22px;
      line-height: 24px;
    }
  `,
  h3: css`
    font-weight: ${bold ? '700' : '500'};
    font-size: 25px;
    line-height: 27px;

    @media screen and (max-width: 975px) {
      font-size: 18px;
      line-height: 22px;
    }

    @media screen and (max-width: 768px) {
      font-size: 16px;
      line-height: 22px;
    }
  `,
  h4: css`
    font-weight: ${bold ? '700' : '500'};
    font-size: 20px;
    line-height: 22px;

    @media screen and (max-width: 975px) {
      font-size: 16px;
      line-height: 18px;
    }

    @media screen and (max-width: 768px) {
      font-size: 14px;
      line-height: 16px;
    }
  `,
});

export const Heading = styled.h1<StyledHeadingProps>`
  color: ${({ color = '#4f4f4f' }) => color};
  text-align: ${({ textAlign = 'left' }) => textAlign};
  font-family: 'Inter';
  font-style: normal;
  ${({ variant = 'h1', bold = false }) => variantModifiers(bold)[variant]};
  @media screen and (max-width: 975px) {
    text-align: left;
  }
  ${({ margin }) => marginModifier(margin)};
`;
