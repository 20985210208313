import React, { Component } from 'react';

import SimpleContainer from '../SimpleContainer';
import NotFound from '../NotFound';

interface Props {}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <SimpleContainer>
          <NotFound />
        </SimpleContainer>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
